import { ACCOUNT, ACTIVITY, ORDER, PAGE, USER, INTEGRAL } from "until/enum";

const routes = [
    {
        path: PAGE.BASE,
        loadPath: 'pages/order'
    },
    // 会员
    {
        path: ACCOUNT.BASE,
        loadPath: 'pages/account'
    },
    {
        path: `${ACCOUNT.UPDATE}/:id`,
        loadPath: "pages/account/accountDetail"
    },
    // 订单
    {
        path: ORDER.BASE,
        loadPath: "pages/order"
    },
    {
        path: `${ORDER.CREATE}/:accountId/:mobile`,
        loadPath: "pages/order/orderDetail"
    },
    {
        path: `${ORDER.UPDATE}/:orderId`,
        loadPath: "pages/order/orderDetail"
    },
    {
        path: ORDER.CREATE,
        loadPath: "pages/order/orderDetail"
    },
    // 管理员
    {
        path: USER.BASE,
        loadPath: "pages/user"
    },
    {
        path: USER.LOGIN,
        loadPath: "pages/user/login"
    },
    {
        path: USER.UPDATE,
        loadPath: "pages/user/userDetail"
    },
    {
        path: USER.AI,
        loadPath: "pages/user/ai"
    },
    // 积分
    {
        path: `${USER.BASE}${INTEGRAL.BASE}`,
        loadPath: "pages/integral"
    },
    // 活动
    {
        path: `${USER.BASE}${ACTIVITY.BASE}`,
        loadPath: "pages/activity"
    },
    {
        path: `${USER.BASE}${ACTIVITY.CREATE}`,
        loadPath: "pages/activity/activityDetail"
    },
    {
        path: `${USER.BASE}${ACTIVITY.UPDATE}/:activityId`,
        loadPath: "pages/activity/activityDetail"
    },
];

export default routes;
