import { PullStatus } from "antd-mobile/es/components/pull-to-refresh";

// 服务器信息
export const SECRET_ID = "AKIDiIdy99tSvHk7b5TozyhBe5JLB9Ts7LQP";
export const SECRET_KEY = "1vkT9j8UkrsjbKzTm3LdXINIE4ymfVK8";
export const COS_BUCKET = "boshi-1317871889";
export const COS_REGION = "ap-guangzhou";

// 基本页面
export enum PAGE {
  BASE = "/",
  ERROR = "*",
}

// 用户 接口页面路径
export enum ACCOUNT {
  BASE = "/account",
  GET = "/account/get",
  LIST = "/account/list",
  CREATE = "/account/create",
  UPDATE = "/account/update",
  DELETE = "/account/delete",
}

// 订单 接口页面路径
export enum ORDER {
  BASE = "/order",
  GET = "/order/get",
  LIST = "/order/list",
  CREATE = "/order/create",
  UPDATE = "/order/update",
  DELETE = "/order/delete",
  SEND_PICK_UP_SMS = "/order/sendPickUpSms",
}

// 活动 接口页面路径
export enum ACTIVITY {
  BASE = "/activity",
  GET = "/activity/get",
  LIST = "/activity/list",
  CREATE = "/activity/create",
  UPDATE = "/activity/update",
  DELETE = "/activity/delete",
}

// 积分 接口页面路径
export enum INTEGRAL {
  BASE = "/integral",
  GET = "/integral/get",
  UPDATE = "/integral/update",
  RESET = "/integral/reset",
}

// 管理员 接口页面路径
export enum USER {
  BASE = "/user",
  LOGIN = "/user/login",
  GET = "/user/get",
  UPDATE = "/user/update",
  AI = "/user/ai",
}

// 分类 接口路径
export enum CLASS {
  LIST = "/class/list",
  CREATE = "/class/create",
  UPDATE = "/class/update",
  DELETE = "/class/delete",
}

// 缓存枚举
export enum STORAGE_KEY {
  TOKEN = "token",
  USER_INFO = "user_info",
}

// 开发管理员ID
export const ADMIN_ID = 2;

// 接口状态码
export enum STATUS_CODE {
  SUCCESS = 200, // 成功
  PARAMS_FALSE = 400, // 传参错误
  URL_FALSE = 404, // 路径错误
  TOKEN_FALSE = 401, // 用户凭证失效
  ROLE_FALSE = 403, // 用户无权限
  FAIL = 500, // 请求错误
}

// 活动短信模版状态
export enum ACTIVITY_STATUS {
  CREATE = "create_template", // 新建短信模版
  UPLOAD = "upload_template", // 上传模版到服务器
  SELECT = "select_account", // 选择群发会员
  FINISH = "send_sms_finish", // 已发送
}

// 度数类型
export enum DEGREE_LABEL {
  MYOPIA = "球镜(DS)", // 近视
  ASTIGMATISM = "柱镜(DC)", // 散光
  HYPEROPIA = "远视",
  PRESBYOPIA = "老花",
  AXIAL_POSITION = "轴位",
  PUPIL_DISTANCE = "瞳距(mm)",
  NAKED = "裸眼",
  CORRECTION = "矫正",
  ADD = "ADD",
}

// 度数类型文本
export enum DEGREE_TITLE {
  myopia = "球镜(DS)", // 近视
  astigmatism = "柱镜(DC)", // 散光
  hyperopia = "远视",
  presbyopia = "老花",
  axial_position = "轴位",
  pupil_distance = "瞳距(mm)",
  naked = "裸眼",
  correction = "矫正",
  ADD = "ADD",
}

// 度数类型键值
export enum DEGREE_TYPE {
  MYOPIA = "myopia", // 近视
  ASTIGMATISM = "astigmatism", // 散光
  HYPEROPIA = "hyperopia", // 远视
  PRESBYOPIA = "presbyopia", // 老花
  AXIAL_POSITION = "axial_position", // 轴位
  PUPIL_DISTANCE = "pupil_distance", // 瞳距
  NAKED = "naked", // 裸眼
  CORRECTION = "correction", // 矫正
  ADD = "ADD", // 下加光
}

// 配镜类型
export enum OPTICAL_LABEL {
  FRAME = "镜框", // 镜框
  LENS = "镜片", // 镜片
  PRICE = "价格", // 价格
}

// 配镜类型键值
export enum OPTICAL_TYPE {
  FRAME = "frame", // 镜框
  LENS = "lens", // 镜片
  PRICE = "price", // 价格
}

// 其他操作键值
export enum OTHER_OPERA {
  IMAGE = "images", // 图片
  REMARK = "remark", // 备注
}

// 短信发送类型
export enum SMS_SEND_TYPE {
  IMMEDIATELY = "send_immediately", // 立即发送
  SCHEDULED = "send_scheduled", // 定时发送
}

// 手机号码正则
export const MobilePattern = "^1[3456789]\\d{9}$";

// 页面下拉状态文本
export const statusRecord: Record<PullStatus, string> = {
  pulling: "用力拉",
  canRelease: "松开吧",
  refreshing: "玩命加载中...",
  complete: "好啦",
};
